<template>
  <div>
    <div class="row">
      <div class="col-sm-8">
        <h2 class="title">Checkout Cart Summary</h2>

        <!-- table s -->
        <div class="table-responsive form-group" style="margin-top:20px;">
          <table class="table">
            <tbody>
              <tr v-for="(itemp, index) in cart_products" :key="index">
                <!-- <td class="text-center col-md-1" style="vertical-align: middle; ">
											<input type="checkbox" value="true" checked v-model="itemp.selected"/>
                </td>-->
                <td class="text-center col-md-1">
                  <a>
                    <img
                      width="70px"
                      :src="itemp.imgpath[0]"
                      alt="Aspire Ultrabook Laptop"
                      title="Aspire Ultrabook Laptop"
                      class="img-thumbnail"
                    />
                  </a>
                </td>
                <td class="text-left col-md-4">
                  <div class="row">
                    <div class="col-md-12" style="font-size:14px; margin-bottom:5px;">
                      <a @click="pushToItem(itemp.item_id)">
                        <b>
                          <span
                            class="badge"
                            style="background: rgb(217, 83, 79);"
                            v-if="itemp.flash_dealid"
                          >Flash Deal</span>
                          {{ itemp.product_name }}
                        </b>
                      </a>
                    </div>
                  </div>

                  <div class="row" v-if="itemp.store_name">
                    <div class="col-md-12">Store: {{itemp.store_name}}</div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      Delivering By :
                      <a>{{ pickDeiveryPrice(itemp.item_id, index) }}</a> &nbsp;
                      <!-- <small>
														<a v-if="!showDeliveryPartners[index]" @click="SetshowDeliveryPartners(index)">Change</a>
														<a v-if="showDeliveryPartners[index]" @click="showDeliveryPartners = []">Close</a>
                      </small>-->
                      <br />
                      <transition name="fade">
                        <CommonOptionsList
                          v-if="showDeliveryPartners[index]"
                          v-bind:title="'Delivery Partners'"
                          v-bind:data="filterDeliveryratesByPId(itemp.item_id)"
                          v-bind:selectedvalue="''"
                          v-bind:itemindex="index"
                          @clicked="onClickDeliveryPartner"
                        />
                      </transition>
                      <br />
                    </div>
                  </div>

                  <!-- <div class="row" v-for="(item, index) in fetchAttributes(itemp.available_attribute, itemp.changeAttBoj)" :key="index">
												<div class="col-md-12">								
													{{item}}
												</div>
                  </div>-->
                </td>
                <td class="text-left col-md-2">
                  <div class="left-content-product">
                    <div class="content-product-right">
                      <div>
                        <div class="form-group box-info-product">
                          <h6>Only {{itemp.primary_product.quantity - itemp.primary_product.selected_qty}} items left</h6>
                          <div class="form-group" style="width:150px;">
                            <div class="input-group">
                              <div
                                class="input-group-addon"
                                v-on:click="quantityDown(itemp, itemp.primary_product.selected_qty)"
                                style="cursor:pointer; font-weight:800;"
                              >-</div>
                              <input
                                type="text"
                                name="quantity"
                                v-model="itemp.primary_product.selected_qty"
                                v-on:change="changeQty(itemp, itemp.primary_product.quantity)"
                                class="form-control"
                                placeholder="Qty"
                                style="text-align:center; font-weight:800;"
                              />
                              <div
                                class="input-group-addon"
                                v-on:click="quantityUp(itemp, itemp.primary_product.selected_qty, itemp.primary_product.quantity)"
                                style="cursor:pointer; font-weight:800;"
                              >+</div>
                            </div>
                          </div>
                          <!-- <div class="option quantity">
																<div class="input-group quantity-control" unselectable="on" style="-webkit-user-select: none;">
																	<label>Qty</label>
																	<input class="form-control" type="text" name="quantity" v-model="itemp.primary_product.selected_qty" v-on:change="changeQty(itemp)" 
																	style="width:50px;">
																	<input type="hidden" name="product_id" value="50">
																	<span class="input-group-addon product_quantity_down" v-on:click="quantityDown(itemp, itemp.primary_product.selected_qty)">−</span>
																	<span class="input-group-addon product_quantity_up" v-on:click="quantityUp(itemp, itemp.primary_product.selected_qty, itemp.primary_product.quantity)">+</span>
																</div>
                          </div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  class="text-right"
                  style="vertical-align: middle; "
                >Unit Price : {{ itemp.primary_product.special_price }}</td>
                <td
                  class="text-right"
                  style="vertical-align: middle; "
                >Sub Total : {{ itemp.itemTotal }}</td>
                <td class="text-right" style="vertical-align: middle; ">
                  <button
                    @click="removeCartItems(itemp, index)"
                    type="button"
                    data-toggle="tooltip"
                    title
                    class="btn btn-danger"
                    onclick
                    data-original-title="Remove"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- table e -->
      </div>

      <div class="col-sm-4">
        <h2 class="title">Order Summary</h2>
        <hr />

        <div class="row">
          <div class="col-sm-12">
            <ul class="yt-accordion">
              <li class="accordion-group">
                <h3 class="accordion-heading">
                  <i class="fa fa-plus-square"></i>
                  <span>Delivering Address</span>
                </h3>
                <div class="accordion-inner">
                  <table class="table table-responsive">
                    <tr style="line-height: 30px;">
                      <td style="font-size: 14px;">{{delivery_fullname}}</td>
                      <td class="text-muted" style="width:25px; font-size:10px;">
                        <a @click="changeAddress('delivery')">Change</a>
                      </td>
                    </tr>
                    <div>
                      <tr style="line-height: 30px;">
                        <td
                          style="font-size: 14px;"
                        >{{delivery_address.address_line_1}}, {{delivery_address.address_line_2}}, {{delivery_address.region}}, {{delivery_address.city}}.</td>
                        <td class="text-muted" style="width:25px; font-size:10px;"></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">{{delivery_address.province}}.</td>
                        <td class="text-muted" style="width:25px; font-size:10px;"></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">{{delivery_address.country}}.</td>
                        <td class="text-muted" style="width:25px; font-size:10px;"></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">{{delivery_address.postal_code}}.</td>
                        <td class="text-muted" style="width:25px; font-size:10px;"></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">{{delivery_phone}}</td>
                        <td class="text-muted" style="width:25px; font-size:10px;"></td>
                      </tr>
                    </div>
                  </table>
                </div>
              </li>
              <li class="accordion-group">
                <h3 class="accordion-heading">
                  <i class="fa fa-plus-square"></i>
                  <span>Billing Address</span>
                </h3>
                <div class="accordion-inner">
                  <table class="table table-responsive">
                    <tr style="line-height: 30px;">
                      <td style="font-size: 14px;">{{billing_fullname}}</td>
                      <td class="text-muted" style="width:25px; font-size:10px;">
                        <a @click="changeAddress('billing')">Change</a>
                      </td>
                    </tr>
                    <div>
                      <tr style="line-height: 30px;">
                        <td
                          style="font-size: 14px;"
                        >{{billing_address.address_line_1}}, {{billing_address.address_line_2}}, {{billing_address.region}}, {{billing_address.city}}.</td>
                        <td class="text-muted" style="width:25px; font-size:10px;"></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">{{billing_address.province}}.</td>
                        <td class="text-muted" style="width:25px; font-size:10px;"></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">{{billing_address.country}}.</td>
                        <td class="text-muted" style="width:25px; font-size:10px;"></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">{{billing_address.postal_code}}.</td>
                        <td class="text-muted" style="width:25px; font-size:10px;"></td>
                      </tr>
                      <tr style="line-height: 30px;">
                        <td style="font-size: 14px;">{{billing_phone}}</td>
                        <td class="text-muted" style="width:25px; font-size:10px;"></td>
                      </tr>
                    </div>
                  </table>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <h4 class="title">Payment</h4>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <table class="table table-responsive">
              <tr style="line-height: 30px;">
                <td style="font-size: 14px;">Subtotal</td>
                <td class="text-right" style="width:auto; font-size:18px;">Rs. {{ subTotal }}</td>
              </tr>
              <tr style="line-height: 30px;">
                <td style="font-size: 14px;">Shipping Fee</td>
                <td class="text-right" style="width:auto; font-size:18px;">Rs. {{shippingfee}}</td>
              </tr>
              <!-- <tr style="line-height: 30px;">
									<td  style="font-size: 14px;">Seller Voucher</td><td class="text-right" style="width:auto; font-size:18px;">- Rs 0</td>
              </tr>-->
            </table>
          </div>
        </div>
        <!-- <div class="row">
							<div class="col-sm-12">
								<div class="input-group btn-block quantity ">
								<input type="text" name="quantity" placeholder="Enter Voucher Code" value="" size="1" class="form-control">
								<span class="input-group-btn" style="padding: 0px;">
								<button type="submit" data-toggle="tooltip" title="" class="btn btn-primary" data-original-title="Update" style="height: 41px;">Apply</button>
								</span></div>
							</div> 
        </div>-->

        <div class="row">
          <div class="col-sm-4" style="padding-top:15px;">
            <h4>Total</h4>
          </div>
          <div class="col-sm-8 text-right" style="font-weight:bold;">
            <h2>Rs. {{ subTotal + shippingfee }}</h2>
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-md-12">
            <a
              data-toggle="modal"
              style="width:100%; background-color: #ffd300!important; color: black; font-weight: 600;"
              class="btn proceed-btn"
              @click="checkoutModal"
            >Proceed To Checkout</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="addressModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addressModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="showContent==1" id="addressModalLongTitle">
              <span v-if="changeBillingAddress">Billing</span>
              <span v-else>Delivering</span> Address
            </h5>
            <div class="row align-contents" v-if="showContent==2">
              <h2 class="modal-title" id="addressModalLongTitle"></h2>
            </div>
            <div class="row align-contents" v-if="showContent==3">
              <h2
                class="modal-title"
                style="font-size:20px; margin-top:5px;"
                id="addressModalLongTitle"
              >Bank Transfer option</h2>
            </div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top:-20px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" v-if="showContent==1">
              <!-- showContent 1= address, 2 =checkout, 3=bank-->
              <div class="col-sm-12">
                <div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <b>Personal Details</b>
                          <hr style="margin-bottom: 5px;" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-md-12 required">
                          <label class="control-label" for="input-firstname">Full Name</label>
                          <input
                            type="text"
                            name="firstname"
                            @change="AddressNamePhone()"
                            v-model="fullname"
                            placeholder="Full Name"
                            id="input-firstname"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-md-12 required">
                          <label class="control-label" for="input-firstname">Phone Number</label>
                          <input
                            type="number"
                            name="phonenumber"
                            @change="AddressNamePhone()"
                            v-model="phonenumber"
                            placeholder="Phone Number"
                            id="input-firstname"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <b>Address</b>
                          <hr style="margin-bottom: 5px;" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-md-12 required">
                          <div class>
                            <label class="control-label">Province</label>
                            <select
                              class="form-control"
                              @change="changeProvince($event.target.value, 0, true)"
                              v-model="selectedprovince"
                            >
                              <option value>--- Please Select ---</option>
                              <option
                                v-for="(item, val) in provinceArray[0]"
                                :key="val"
                                :value="item._id"
                              >{{ item.province_name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-md-12 required">
                          <div class>
                            <label class="control-label">District / Area</label>
                            <select
                              class="form-control"
                              @change="changeRegion($event.target.value, 0, true)"
                              v-model="selectedregion"
                            >
                              <option value>--- Please Select ---</option>
                              <option
                                v-for="(item, val) in regionArray[0]"
                                :key="val"
                                :value="item._id"
                              >{{ item.region_name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-md-12 required">
                          <div class="form-group required">
                            <label class="control-label">City</label>
                            <select class="form-control" v-model="selectedcity">
                              <option value>--- Please Select ---</option>
                              <option
                                v-for="(item, val) in cityArray[0]"
                                :key="val"
                                :value="item._id"
                              >{{ item.city_name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="row">
											<div class=" form-group col-md-12 required">										 
												<div class="form-group required">
													<label class="control-label" for="input-password">Postal Code</label>
													<input type="text" class="form-control" v-model="selectedpostalcode"/>
												</div>							 				 
											</div>
                      </div>-->
                      <div class="row">
                        <div class="form-group col-md-12 required">
                          <div class="form-group required">
                            <label class="control-label" for="input-password">Address Line 1</label>
                            <input type="text" class="form-control" v-model="selectedAddressLine1" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-md-12 required">
                          <div class="form-group required">
                            <label class="control-label" for="input-password">Address Line 2</label>
                            <input type="text" class="form-control" v-model="selectedAddressLine2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="showContent==2">
              <div class="col-md-12 align-contents">
                <!-- <div @click="setAction(1)" class="square-contents align-squares" v-bind:style="activeDiv==1 ? 'background-color:#f9d730;' : '' " >
								<img src="@/assets/image/ic_supiripolacredits@2x.png">
								<div class="squares-text">Supiripola Credit</div>
                </div>-->
                <div class="square-contents align-squarescard">
                  <img src="@/assets/image/ic_credit_card@2x.png" />
                  <div class="squares-text" style="margin-top: 15px">Card Payment</div>
                </div>
                <div
                  @click="setAction(2)"
                  class="square-contents align-squares"
                  v-bind:style="activeDiv==2 ? 'background-color:#f9d730;' : '' "
                >
                  <img src="@/assets/image/ic_cod@2x.png" />
                  <div class="squares-text">Cash on Delivery</div>
                </div>
                <div
                  @click="setAction(3)"
                  class="square-contents align-squares"
                  v-bind:style="activeDiv==3 ? 'background-color:#f9d730;' : '' "
                >
                  <img src="@/assets/image/ic_bank_tranfer@2x.png" />
                  <div class="squares-text">Bank Transfer</div>
                </div>
              </div>
              <div class="row" v-if="showContent==2" style="text-align: center;">
                <button
                  type="button"
                  class="btn proceed-btn"
                  @click="proceed"
                  style="color: black; font-weight: 600; margin-top:10px;"
                >Proceed</button>
              </div>
            </div>
            <div class="row align-contents" v-if="showContent==3">
              <div class="col-md-8">
                <div class="row">
                  <!-- <div class="col-md-4">
									<div class="">Order ID</div>
                  </div>-->
                  <div>
                    <div
                      class="row"
                      style="text-align: center; color: #ffd300; font-size:20px; font-weight: 600;"
                    >{{ subTotal + shippingfee + " "}}LKR</div>
                    <div
                      class="row"
                      style="text-align: center; color: Black; font-size:16px;"
                    >Amount</div>
                  </div>
                </div>
                <div
                  class="row"
                  style="text-align:center; margin-top:20px; margin-bottom:20px; font-size:14px;"
                >
                  You can send your payment to us via bank transfer option and
                  make sure you made the payment before checkout
                </div>
                <div
                  class="row"
                  style="text-align:center; margin-top:20px; margin-bottom:20px; font-size:14px;"
                >
                  It is recommended to include Username in Beneficiary Narration.
                  Cash Deposit , Credit Advice Slip (on Reference : Username should be mentioned).
                  Online transfer , Include Username on Beneficiary Narration.
                  CEFT Transfers are recommended for faster clearance
                </div>
                <div class="row">
                  <div>
                    <div class="form-group" style="width: 100%;">
                      <select
                        v-model="bankTranferObj.bank"
                        @change="setbank($event.target.value)"
                        class="form-control select-arrow"
                        style="width: 100%;"
                      >
                        <option value="-1">Select Bank</option>
                        <option
                          v-for="item in bankOption"
                          :key="item.key"
                          :value="item.key"
                        >{{ item.text }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-md-12"
                    style="width: 100%; border-radius: 5px;background-color: #e5e5e5;"
                  >
                    <div>
                      <div
                        class="col-md-12"
                        style="margin-top:30px; margin-bottom:10px;  font-weight: 600; font-size:14px;"
                      >
                        <div class="col-md-5">Bank</div>
                        <div class="col-md-1">:</div>
                        <div
                          class="col-md-6"
                        >{{ selectedBank.text != undefined ? selectedBank.text : "" }}</div>
                      </div>
                      <div
                        class="col-md-12"
                        style="margin-top:10px; margin-bottom:10px;  font-weight: 600; font-size:14px;"
                      >
                        <div class="col-md-5">Branch</div>
                        <div class="col-md-1">:</div>
                        <div
                          class="col-md-6"
                        >{{ selectedBank.branch != undefined ? selectedBank.branch : ""}}</div>
                      </div>
                      <div
                        class="col-md-12"
                        style="margin-top:10px; margin-bottom:10px;  font-weight: 600;  font-size:14px;"
                      >
                        <div class="col-md-5">Account Name</div>
                        <div class="col-md-1">:</div>
                        <div
                          class="col-md-6"
                        >{{ selectedBank.account_name != undefined ? selectedBank.account_name : " "}}</div>
                      </div>
                      <div
                        class="col-md-12"
                        style="margin-top:10px; margin-bottom:30px;  font-weight: 600; font-size:14px;"
                      >
                        <div class="col-md-5">Account No</div>
                        <div class="col-md-1">:</div>
                        <div
                          class="col-md-6"
                        >{{ selectedBank.account_no != undefined ? selectedBank.account_no : " "}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div style="text-align: left;margin-top:10px; font-weight: 600; font-size:14px;">
                    Attach Slip here
                    <button
                      type="button"
                      class="btn btn-secondary"
                      style="color: black; font-weight: 600;"
                      @click="pickCoverImage()"
                    >Upload</button>
                    <!-- <input type="file" class="inputfile" id="embedpollfileinput" @change="getFile"> -->
                    <input
                      type="file"
                      style="display: none;"
                      ref="coverImage"
                      accept="image/*"
                      @change="getImageRes()"
                    />
                    <p>
                      <small>Maximum upload file size: 2MB</small>
                    </p>
                  </div>
                </div>
                <div class="row" v-if="bankSlipImage">
                  <img
                    style="margin-top:10px; width: auto; height:100px;"
                    slot="image"
                    :src="bankSlipImage"
                  />
                </div>
                <div style="text-align: center;margin-top:10px; margin-bottom:10px;">
                  <button
                    type="button"
                    class="btn proceed-btn"
                    style="color: black; font-weight: 600; font-size: 18px;"
                    :disabled="bankSlipImage==''"
                    @click="submitBankImageSlip"
                  >Make Payment</button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="showContent==1">
            <div>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="NewAddressChange()">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="modal fade" id="checkoutModalCenter" tabindex="-1" role="dialog" aria-labelledby="checkoutModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered " role="document">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="checkoutModalLongTitle">Checkout Options</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" style="margin-top:-20px;">
					<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">

					<div class="module">
						<div class="">
							<ul class="block-infos">
								<li class="info1">
									<div class="inner">
										<i class="fa fa-credit-card"></i>
										<div class="info-cont">
											<a href="#">Card Payment</a>
											<p>Use a credit/debit card to complete the transaction</p>
										</div>
									</div>
								</li>  
								<li @click="checkOut()" class="info4">
									<div class="inner">
										<i class="fa fa-money"></i>
										<div class="info-cont">
											<a >Supiripola Credit</a>
											<p>Use credit option as pyament</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
	
				</div>					
				</div>
			</div> 
    </!-->
    -->
  </div>
</template>
<script>
// @ is an alias to /src
// import ProductTile from '@/components/product/product_tile.vue'
// import UncommonFeatures from '@/components/common/uncommon_features.vue'
// import TrendingCategories from '@/components/category/trending_categories.vue'
import mixinCommon from "@/mixins/APICommon";
import mixinProduct from "@/mixins/buyer/APIProduct";
import mixinProfile from "@/mixins/buyer/APIProfile";
import mixinPromotions from "@/mixins/buyer/APIPromotions";
import CommonOptionsList from "@/components/common/commonOptionsList.vue";
export default {
  name: "cart",
  mixins: [mixinCommon, mixinProfile, mixinProduct, mixinPromotions],
  components: {
    CommonOptionsList
  },
  data() {
    return {
      showModal: 1,
      breadcrumb: [
        {
          text: "Cart",
          href: "/buyer-cart",
          active: false
        },
        {
          text: "Checkout",
          active: true
        }
      ],
      deliveryPartnerData: [
        {
          text: "DHL Standard - Rs.400",
          value: "24d2cs324223422131",
          price: 400
        },
        { text: "DHL Express - Rs.700", value: "v432q4223422131", price: 700 }
      ],
      Deliveryrates: [],
      selectedDeliveryPartner: [],
      showDeliveryPartners: [],
      fullname: "",
      phonenumber: "",
      billing_fullname: "",
      billing_phone: "",
      delivery_fullname: "",
      delivery_phone: "",
      selectedprovince: "",
      selectedregion: "",
      selectedcity: "",
      selectedAddressLine1: "",
      selectedAddressLine2: "",
      selectedpostalcode: "",
      is_biling_address: "",
      countries: [],
      province: [],
      region: [],
      city: [],
      provinceArray: [],
      regionArray: [],
      cityArray: [],
      addressArray: [
        {
          fullname: "",
          phonenumber: "",
          houseno: "",
          country: "",
          province: "",
          region: "",
          city: "",
          postal_code: "",
          is_biling_address: true,
          primary: false
        }
      ],
      address: {
        fullname: "",
        phonenumber: "",
        houseno: "",
        country: "",
        province: "",
        region: "",
        city: "",
        postal_code: "",
        is_biling_address: true,
        is_shipping_address: true,
        primary: false
      },
      delivery_address: {
        fullname: "",
        phonenumber: "",
        houseno: "",
        country: "",
        province: "",
        region: "",
        city: "",
        address_line_1: "",
        address_line_2: "",
        postal_code: "",
        is_biling_address: true,
        is_shipping_address: true,
        primary: false
      },
      billing_address: {
        fullname: "",
        phonenumber: "",
        houseno: "",
        country: "",
        province: "",
        region: "",
        city: "",
        ddress_line_1: "",
        address_line_2: "",
        postal_code: "",
        is_biling_address: true,
        is_shipping_address: true,
        primary: false
      },
      isCheck: true,
      multiAddress: true,
      subTotal: 0,
      shippingfee: 0,
      total: 0,
      no_of_cart_items: 0,
      cart_products: [],
      cartSavedProductIds: [],
      idObj: {
        ids: []
      },
      isVisible: true,
      bill_fname: "",
      bill_lname: "",
      bill_address: "",
      bill_mobile: "",
      shipping_address: "",
      changeBillingAddress: true,
      flashitems: {}
    };
  },
  watch: {
    selectedDeliveryPartner: function(val) {
      // this.pickDeiveryPrice();
    }
  },
  created() {
    this.handleBreadcrumb();
    this.loadByOrder();
  },
  mounted() {
    /* ---------------------------------------------------
		Accordion to Bonus page
	-------------------------------------------------- */
    $("ul.yt-accordion li").each(function() {
      if ($(this).index() > 0) {
        $(this)
          .children(".accordion-inner")
          .css("display", "none");
      } else {
        $(this)
          .find(".accordion-heading")
          .addClass("active");
      }

      var ua = navigator.userAgent,
        event = ua.match(/iPad/i) ? "touchstart" : "click";
      $(this)
        .children(".accordion-heading")
        .bind(event, function() {
          $(this).addClass(function() {
            if ($(this).hasClass("active")) return "";
            return "active";
          });

          $(this)
            .siblings(".accordion-inner")
            .slideDown(350);
          $(this)
            .parent()
            .siblings("li")
            .children(".accordion-inner")
            .slideUp(350);
          $(this)
            .parent()
            .siblings("li")
            .find(".active")
            .removeClass("active");
        });
    });
  },
  methods: {
    checkoutModal: function() {
      this.showModal = 2;
      $("#addressModalCenter").modal("show");
    },
    loadByOrder: async function() {
      await this.handleFalshdeals();

      this.setCartItems();
      this.getPersanalInfo();
      this.getDeliveryrates();
      this.changeCountries("5d8b9e5dfb195816aaea61b3", 0);
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfoNamed();
        if (response.addresses.length === 0) {
          this.addressArray = [
            {
              address: "",
              country: "",
              province: "",
              region: "",
              city: "",
              postal_code: "",
              is_biling_address: true,
              primary: false
            }
          ];
        } else {
          if (
            !response.hasOwnProperty("first_name") ||
            !response.hasOwnProperty("last_name")
          ) {
            this.$swal.fire({
              position: "center",
              type: "error",
              title: "Please Update Your Personal Informaion!",
              showConfirmButton: false,
              timer: 1500
            });
            this.$router.push("/buyer-profile");
            return;
          }

          this.bill_fname = response.first_name;
          this.bill_lname = response.last_name;
          this.bill_mobile = response.mobile;

          if (localStorage.billing_fullname) {
            this.billing_fullname = localStorage.billing_fullname;
          } else {
            if (this.billing_fullname === "") {
              this.billing_fullname = this.bill_fname + " " + this.bill_lname;
            }
          }

          if (localStorage.billing_phone) {
            this.billing_phone = localStorage.billing_phone;
          } else {
            if (this.billing_phone === "") {
              this.billing_phone = this.bill_mobile;
            }
          }

          if (localStorage.delivery_fullname) {
            this.delivery_fullname = localStorage.delivery_fullname;
          } else {
            if (this.delivery_fullname === "") {
              this.delivery_fullname = this.bill_fname + " " + this.bill_lname;
            }
          }

          if (localStorage.delivery_phone) {
            this.delivery_phone = localStorage.delivery_phone;
          } else {
            if (this.delivery_phone === "") {
              this.delivery_phone = this.delivery_phone;
            }
          }

          for (let index = 0; index < response.addresses.length; index++) {
            if (response.addresses[index].is_shipping_address === true) {
              this.delivery_address = {
                address: response.addresses[index].address,
                country: response.addresses[index].country.country_name,
                province: response.addresses[index].province.province_name,
                region: response.addresses[index].region.region_name,
                city: response.addresses[index].city.city_name,
                address_line_1: response.addresses[index].address_line_1,
                address_line_2: response.addresses[index].address_line_2,
                postal_code: response.addresses[index].postal_code,
                is_biling_address: response.addresses[index].is_biling_address,
                is_shipping_address: true
              };
            }

            if (response.addresses[index].is_biling_address === true) {
              this.billing_address = {
                address: response.addresses[index].address,
                country: response.addresses[index].country.country_name,
                province: response.addresses[index].province.province_name,
                region: response.addresses[index].region.region_name,
                city: response.addresses[index].city.city_name,
                address_line_1: response.addresses[index].address_line_1,
                address_line_2: response.addresses[index].address_line_2,
                postal_code: response.addresses[index].postal_code,
                is_biling_address: true,
                is_shipping_address:
                  response.addresses[index].is_shipping_address
              };
            }

            await this.changeCountries(
              response.addresses[index].country._id,
              index
            );
            await this.changeProvince(
              response.addresses[index].province._id,
              index
            );
            await this.changeRegion(
              response.addresses[index].region._id,
              index
            );
            if (response.addresses[index].is_biling_address === true) {
              this.bill_address = response.addresses[index]._id;
            }

            if (response.addresses[index].is_shipping_address === true) {
              this.shipping_address = response.addresses[index]._id;
            }
            this.addressArray = response.addresses;
          }
          this.addressArray = response.addresses;
        }
      } catch (error) {
        console.log(error);
      }
    },
    getDeliveryrates: async function() {
      try {
        this.cart_products;
        let pid_obj = {
          product: []
        };

        for (let index = 0; index < this.cart_products.length; index++) {
          pid_obj.product.push(this.cart_products[index].item_id);
        }

        let repsonse = await this.fetchDeliveryRatesByPIds(pid_obj);

        this.Deliveryrates = repsonse;

        for (let index = 0; index < this.Deliveryrates.length; index++) {
          if (localStorage.selectedDeliveryPartner) {
            this.selectedDeliveryPartner = JSON.parse(
              localStorage.selectedDeliveryPartner
            );
          } else {
            this.selectedDeliveryPartner.push(
              this.Deliveryrates[index].product_id +
                "_" +
                this.Deliveryrates[index].deliver_partners[0].deliver_rate_id +
                "_Standard"
            );
          }

          // this.pickDeiveryPrice(this.Deliveryrates[index].product_id, index);
        }

        this.calculateShippingfee();
      } catch (error) {
        console.log(error);
      }
    },
    filterDeliveryratesByPId(pid) {
      var array = this.Deliveryrates.filter(function(delrates) {
        return delrates.product_id === pid;
      });

      let delrateArray = [];

      for (let index = 0; index < array[0].deliver_partners.length; index++) {
        var obj = {};

        obj.text =
          array[0].deliver_partners[index].deliver.company_name +
          " Standard - Rs." +
          array[0].deliver_partners[index].deliver_standard_rate;
        obj.value =
          pid +
          "_" +
          array[0].deliver_partners[index].deliver_rate_id +
          "_Standard";
        obj.price = array[0].deliver_partners[index].deliver_standard_rate;

        delrateArray.push(obj);

        if (array[0].deliver_partners[index].deliver_special_rate) {
          var obj = {};

          obj.text =
            array[0].deliver_partners[index].deliver.company_name +
            " Express - Rs." +
            array[0].deliver_partners[index].deliver_special_rate;
          obj.value =
            pid +
            "_" +
            array[0].deliver_partners[index].deliver_rate_id +
            "_Express";
          obj.price = array[0].deliver_partners[index].deliver_special_rate;

          delrateArray.push(obj);
        }
      }

      return delrateArray;
    },
    onClickDeliveryPartner(value) {
      this.$set(this.selectedDeliveryPartner, value.index, value.value);
      this.$set(this.showDeliveryPartners, value.index, false);
      this.calculateShippingfee();
    },
    SetshowDeliveryPartners(index) {
      this.showDeliveryPartners = [];
      this.$set(this.showDeliveryPartners, index, true);
    },
    pickDeiveryPrice: function(pid, index) {
      return (
        this.cart_products[index].delivery_company +
        " : Rs. " +
        this.cart_products[index].deliver_charge
      );

      if (this.Deliveryrates.length === 0) {
        return "";
      }

      let selectedDeliveryPartner = this.cart_products[index]
        .selectedDeliveryPartner;
      // var arraySelecteddelrate =  this.selectedDeliveryPartner.filter(function(selecteddelrates) {
      // 	var check = selecteddelrates.includes(pid);
      // 	return check == true;
      // });

      if (!this.cart_products[index].selectedDeliveryPartner) {
        selectedDeliveryPartner = this.selectedDeliveryPartner[index];
      }

      let did = "";
      let dmethod = "";

      if (selectedDeliveryPartner != undefined) {
        let pickvalue = selectedDeliveryPartner;
        var res = pickvalue.split("_");
        did = res[1];
        dmethod = res[2];
      } else {
        return " ";
      }

      var delarray = this.Deliveryrates.filter(function(delrates) {
        return delrates.product_id === pid;
      });

      var delpartnerarray = delarray[0].deliver_partners.filter(function(
        delpartner
      ) {
        return delpartner.deliver_rate_id === did;
      });

      if (delpartnerarray.length > 0) {
        if (dmethod === "Standard") {
          return (
            delpartnerarray[0].deliver.company_name +
            " Standard - Rs. " +
            delpartnerarray[0].deliver_standard_rate
          );
        } else {
          return (
            delpartnerarray[0].deliver.company_name +
            " Express - Rs. " +
            delpartnerarray[0].deliver_special_rate
          );
        }
      } else {
        return " ";
      }
      return " ";
    },
    pickDeiveryPriceValue: function(index) {
      var arraySelecteddelrate = this.selectedDeliveryPartner.filter(function(
        selecteddelrates
      ) {
        var check = selecteddelrates.includes(index);
        return check == true;
      });

      let did = "";
      let dmethod = "";

      if (arraySelecteddelrate.length > 0) {
        let pickvalue = arraySelecteddelrate[0];
        var res = pickvalue.split("_");
        did = res[1];
        dmethod = res[2];
      } else {
        return 0;
      }

      var delarray = this.Deliveryrates.filter(function(delrates) {
        return delrates.product_id === index;
      });

      var delpartnerarray = delarray[0].deliver_partners.filter(function(
        delpartner
      ) {
        return delpartner.deliver_rate_id === did;
      });

      if (delpartnerarray.length > 0) {
        if (dmethod === "Standard") {
          return delpartnerarray[0].deliver_standard_rate;
        } else {
          return delpartnerarray[0].deliver_special_rate;
        }
      } else {
        return 0;
      }
      return 0;
    },
    calculateShippingfee() {
      let fee = 0;
      for (let index = 0; index < this.cart_products.length; index++) {
        if (this.cart_products[index].selected) {
          fee = fee + Math.ceil(this.cart_products[index].deliver_charge);
        }
      }
      this.shippingfee = fee;
    },
    handleFalshdeals: async function() {
      try {
        let flashdata = await this.getFlashDeals();
        if (flashdata.time_now) {
          this.flashitems = flashdata;
        } else {
          this.flashitems = {};
        }
      } catch (error) {
        console.log(error);
      }
    },
    setCartItems: async function() {
      if (!localStorage.cart_product_ids_checkout) {
        this.$router.push("/buyer-cart");
      }
      let productIDArray = JSON.parse(
        localStorage.getItem("cart_product_ids_checkout")
      );

      this.cart_products = productIDArray.ids;
      this.cart_products = productIDArray.ids.map(obj => {
        obj.selected = true;
        obj.itemTotal =
          obj.primary_product.special_price * obj.primary_product.selected_qty;
        delete obj.flash_dealid;
        if (this.flashitems.now.hasOwnProperty("products")) {
          let flashproducts = this.flashitems.now.products;
          for (let index = 0; index < flashproducts.length; index++) {
            let fitem_sid = flashproducts[index].simple_product._id;
            let cartitem_sid = obj.primary_product._id;

            if (fitem_sid === cartitem_sid) {
              obj.flash_dealid = flashproducts[index].flash_deal;
            }
          }
        }
        return obj;
      });

      let total = this.cart_products.reduce(function(total, item) {
        return total + item.itemTotal;
      }, 0);

      this.subTotal = total;
      this.total = total;

      this.no_of_cart_items = this.cart_products.length;

      this.checkAvailableStock();
    },
    processToCheckOut: function(index, obj) {
      alert("processToCheckOut");
    },
    removeCartItems: async function(itemObj, index) {
      let tempTableData = this.cart_products;
      tempTableData.splice(index, 1);
      this.cart_products = tempTableData;
      this.idObj.ids = [];

      if (this.cart_products.length === 0) {
        this.idObj.ids = [];
        localStorage.setItem(
          "cart_product_ids_checkout",
          JSON.stringify(this.idObj)
        );
      } else {
        this.cart_products.map(obj => {
          this.idObj.ids.push(obj);
          localStorage.setItem(
            "cart_product_ids_checkout",
            JSON.stringify(this.idObj)
          );
        });
      }

      this.no_of_cart_items = this.idObj.ids.length;
      this.setCartItems();
      this.calculateTotalfee();
    },
    calculateTotalfee() {
      let itemnotchecked = true;

      let total = this.cart_products.reduce(function(total, item) {
        if (item.selected) {
          return total + item.itemTotal;
        } else {
          itemnotchecked = false;
          return total;
        }
      }, 0);

      this.subTotal = total;
      this.total = total;

      if (!itemnotchecked) {
        this.selectAllcheck = false;
      } else {
        this.selectAllcheck = true;
      }

      this.calculateShippingfee();
    },
    changeQty: function(item, maxxVal) {
      item.primary_product.selected_qty = parseInt(
        item.primary_product.selected_qty
      );
      if (isNaN(item.primary_product.selected_qty)) {
        item.primary_product.selected_qty = 1;
      }

      if (item.primary_product.selected_qty >= maxxVal) {
        item.primary_product.selected_qty = maxxVal;
      }

      if (item.primary_product.selected_qty < 1) {
        item.primary_product.selected_qty = 1;
      }

      this.cart_products = this.cart_products.map(obj => {
        if (obj.item_id === item.item_id) {
          obj.itemTotal =
            obj.primary_product.special_price *
            item.primary_product.selected_qty;
        }
        obj.total = obj.itemTotal + obj.itemTotal;
        return obj;
      });

      let total = this.cart_products.reduce(function(total, item) {
        return total + item.itemTotal;
      }, 0);

      this.subTotal = total;
      this.total = total;

      this.calculateTotalfee();
    },
    quantityDown: function(item, val) {
      val = parseInt(val);
      if (val === 1) {
        item.primary_product.selected_qty = 1;
        return;
      }

      this.cart_products = this.cart_products.map(obj => {
        if (obj.item_id === item.item_id) {
          item.primary_product.selected_qty = val - 1;
          obj.itemTotal =
            obj.primary_product.special_price *
            item.primary_product.selected_qty;
        }
        obj.total = obj.itemTotal + obj.itemTotal;
        return obj;
      });

      let total = this.cart_products.reduce(function(total, item) {
        return total + item.itemTotal;
      }, 0);

      this.subTotal = total;
      this.total = total;

      this.calculateTotalfee();
    },
    quantityUp: function(item, val, maxxVal) {
      val = parseInt(val);
      if (val === maxxVal) {
        item.primary_product.selected_qty = maxxVal;
        return;
      }

      this.cart_products = this.cart_products.map(obj => {
        if (obj.item_id === item.item_id) {
          item.primary_product.selected_qty = val + 1;
          obj.itemTotal =
            obj.primary_product.special_price *
            item.primary_product.selected_qty;
        }
        obj.total = obj.itemTotal + obj.itemTotal;
        return obj;
      });

      let total = this.cart_products.reduce(function(total, item) {
        return total + item.itemTotal;
      }, 0);

      this.subTotal = total;
      this.total = total;

      this.calculateTotalfee();
    },
    changeCountries: async function(event, index, useraction = false) {
      try {
        let response = await this.getProvince(event);
        this.province = response;

        this.$set(this.provinceArray, index, response);
        this.regionArray[index] = [];
        this.cityArray[index] = [];

        // this.provinceArray[index] = response;

        // // RESETING
        if (Object.keys(response).length === 0 || useraction === true) {
          this.addressArray[index].province = "";
          this.addressArray[index].region = "";
          this.addressArray[index].city = "";
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    changeProvince: async function(event, index, useraction = false) {
      try {
        let response = await this.getRegion(event);
        this.region = response;

        this.$set(this.regionArray, index, response);

        // RESETING
        this.cityArray[index] = [];
        if (Object.keys(response).length === 0 || useraction === true) {
          this.addressArray[index].region = "";
          this.addressArray[index].city = "";
          this.selectedregion = "";
          this.selectedcity = "";
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    changeRegion: async function(event, index, useraction = false) {
      try {
        let response = await this.getCity(event);
        this.city = response;

        this.$set(this.cityArray, index, response);

        // RESETING
        if (Object.keys(response).length === 0 || useraction === true) {
          this.addressArray[index].city = "";
          this.selectedcity = "";
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    clickButton: function(val, tickindex) {
      if (this.addressArray[tickindex].is_biling_address === false) {
        if (this.addressArray.length < 2) {
          this.addNewClick();
          this.changeCountries("5d8b9e5dfb195816aaea61b3", 1);
        }
      }
      for (let index = 0; index < this.addressArray.length; index++) {
        if (val === false) {
          if (tickindex != index) {
            this.addressArray[index].is_biling_address = true;
          }
        } else {
          if (tickindex != index) {
            this.addressArray[index].is_biling_address = false;
          }
        }
      }

      if (val === true) {
        this.isCheck = false;
        this.multiAddress = false;
      } else {
        this.isCheck = true;
        this.multiAddress = true;
      }
    },
    addNewClick: function() {
      if (this.addressArray.length <= 1) {
        if (this.valIndex === 0) {
          this.addressArray[0].primary = true;
        }
        this.valIndex = this.valIndex + 1;
        this.addressArray.push(this.address);
      }
    },
    DeleteClick: async function(index, itemid) {
      this.addressArray.splice(index, 1);
      this.provinceArray.splice(index, 1);
      this.addressArray[0].is_biling_address = true;
    },
    pushCart() {
      this.$router.push("/buyer-cart").catch(err => {});
    },
    AddressNamePhone() {
      if (this.changeBillingAddress) {
        this.billing_fullname = this.fullname;
        this.billing_phone = this.phonenumber;

        localStorage.setItem("billing_fullname", this.billing_fullname);
        localStorage.setItem("billing_phone", this.billing_phone);
      } else {
        this.delivery_fullname = this.fullname;
        this.delivery_phone = this.phonenumber;

        localStorage.setItem("delivery_fullname", this.delivery_fullname);
        localStorage.setItem("delivery_phone", this.delivery_phone);
      }
    },
    changeAddress: async function(type) {
      this.showModal = 1;
      if (type === "billing") {
        this.changeBillingAddress = true;

        for (let index = 0; index < this.addressArray.length; index++) {
          if (this.addressArray[index].is_biling_address === true) {
            await this.changeProvince(this.addressArray[index].province._id, 0);
            await this.changeRegion(this.addressArray[index].region._id, 0);

            this.selectedprovince = this.addressArray[index].province._id;
            this.selectedregion = this.addressArray[index].region._id;
            this.selectedcity = this.addressArray[index].city._id;
            this.selectedAddressLine1 = this.addressArray[index].address_line_1;
            this.selectedAddressLine2 = this.addressArray[index].address_line_2;
            this.selectedpostalcode = this.addressArray[index].postal_code;
          }
        }

        if (this.billing_fullname === "") {
          this.billing_fullname = this.bill_fname + " " + this.bill_lname;
        }

        if (this.billing_phone === "") {
          this.billing_phone = this.bill_mobile;
        }

        this.fullname = this.billing_fullname;
        this.phonenumber = this.billing_phone;
      } else {
        this.changeBillingAddress = false;

        for (let index = 0; index < this.addressArray.length; index++) {
          if (this.addressArray[index].is_shipping_address === true) {
            await this.changeProvince(this.addressArray[index].province._id, 0);
            await this.changeRegion(this.addressArray[index].region._id, 0);

            this.selectedprovince = this.addressArray[index].province._id;
            this.selectedregion = this.addressArray[index].region._id;
            this.selectedcity = this.addressArray[index].city._id;
            this.selectedAddressLine1 = this.addressArray[index].address_line_1;
            this.selectedAddressLine2 = this.addressArray[index].address_line_2;
            this.selectedpostalcode = this.addressArray[index].postal_code;
          }

          if (this.delivery_fullname === "") {
            this.delivery_fullname = this.bill_fname + " " + this.bill_lname;
          }

          if (this.delivery_phone === "") {
            this.delivery_phone = this.bill_mobile;
          }

          this.fullname = this.delivery_fullname;
          this.phonenumber = this.delivery_phone;
        }
      }

      $("#addressModalCenter").modal("show");
    },
    checkOut: async function() {
      let items = [];

      await this.handleFalshdeals();

      for (let index = 0; index < this.cart_products.length; index++) {
        if (this.cart_products[index].flash_dealid) {
          if (!this.flashitems.now.products) {
            this.cart_products[index].selected = false;
            delete this.cart_products[index].flash_dealid;
          } else {
            delete this.cart_products[index].flash_dealid;
            let flashproducts = this.flashitems.now.products;

            for (let index2 = 0; index2 < flashproducts.length; index2++) {
              let fitem_sid = flashproducts[index2].simple_product._id;
              let cartitem_sid = this.cart_products[index].primary_product._id;

              if (fitem_sid === cartitem_sid) {
                this.cart_products[index].flash_dealid =
                  flashproducts[index2].flash_deal;
              }
            }
          }
        }

        if (this.cart_products[index].selected == true) {
          let self = this;
          var arraySelecteddelrate = this.selectedDeliveryPartner.filter(
            function(selecteddelrates) {
              var check = selecteddelrates.includes(
                self.cart_products[index].item_id
              );
              return check == true;
            }
          );
          // console.log(arraySelecteddelrate);
          let did = "";
          let dmethod = "";

          if (arraySelecteddelrate.length > 0) {
            let pickvalue = arraySelecteddelrate[0];
            var res = pickvalue.split("_");
            did = res[1];
            dmethod = res[2];
          } else {
            return " ";
          }

          var delarray = this.Deliveryrates.filter(function(delrates) {
            return delrates.product_id === self.cart_products[index].item_id;
          });

          var delpartnerarray = delarray[0].deliver_partners.filter(function(
            delpartner
          ) {
            return delpartner.deliver_rate_id === did;
          });

          let dtype = 1;

          if (dmethod === "Standard") {
            dtype = 1;
          } else {
            dtype = 2;
          }
          let delivery_partner = "";
          let delivery_rate = "";
          this.Deliveryrates.forEach(element => {
            if (element.product_id === this.cart_products[index].item_id) {
              delivery_partner = element.deliver_partners[0].deliver._id;
              delivery_rate = element.deliver_partners[0]._id;
            }
          });

          let itemobj = {
            simple_product_id: this.cart_products[index].primary_product._id,
            delivery_partner: delivery_partner,
            delivery_rate: delivery_rate,
            delivery_type: 1,
            qty: this.cart_products[index].primary_product.selected_qty
          };
          if (this.cart_products[index].flash_dealid) {
            itemobj.flash_deal = this.cart_products[index].flash_dealid;
          }

          items.push(itemobj);
        }
      }

      let final_checkout = {
        address_id: this.shipping_address,
        billing_address_id: this.bill_address,
        billing_mobile: this.billing_phone,
        shipping_mobile: this.delivery_phone,
        billing_name: this.billing_fullname,
        shipping_name: this.delivery_fullname,
        payment_type: 1,
        items: items
      };
      console.log(final_checkout);
      this.postCheckout(final_checkout);
    },
    postCheckout: async function(obj) {
      try {
        let response = await this.postCheckoutOrder(obj);

        $("#addressModalCenter").modal("hide");

        let productIDArrayMain = JSON.parse(
          localStorage.getItem("cart_product_ids")
        );
        let productIDArrayCheckout = this.cart_products;

        let checkoutids = [];
        if (productIDArrayMain) {
          for (let index = 0; index < productIDArrayCheckout.length; index++) {
            if (productIDArrayCheckout[index].selected == true) {
              checkoutids.push(
                productIDArrayCheckout[index].primary_product._id
              );
            }
          }

          var cartRemainingArray = productIDArrayMain.ids.filter(function(
            item
          ) {
            if (item.selected == true) {
              return !checkoutids.includes(item.primary_product._id);
            }
          });

          productIDArrayMain.ids = cartRemainingArray;
        }

        console.log(productIDArrayMain);

        localStorage.setItem(
          "cart_product_ids",
          JSON.stringify(productIDArrayMain)
        );

        localStorage.removeItem("billing_fullname");
        localStorage.removeItem("billing_phone");

        localStorage.removeItem("delivery_fullname");
        localStorage.removeItem("delivery_phone");

        localStorage.removeItem("cart_product_ids_checkout");

        this.$swal.fire({
          position: "center",
          type: "success",
          title: "ORDER PLACED",
          showConfirmButton: false,
          timer: 1500
        });
        this.$router.push("/").catch(()=>{});
      } catch (error) {
        console.log(error);
      }
    },
    NewAddressChange: async function() {
      try {
        let obj = {};
        let _id = "";

        if (this.changeBillingAddress) {
          for (let index = 0; index < this.addressArray.length; index++) {
            if (this.addressArray[index].is_biling_address === true) {
              _id = this.addressArray[index]._id;
            }
          }

          obj = {
            _id: _id,
            address: {
              address_line_1: this.selectedAddressLine1,
              address_line_2: this.selectedAddressLine2,
              country: "5d8b9e5dfb195816aaea61b3",
              province: this.selectedprovince,
              region: this.selectedregion,
              city: this.selectedcity,
              primary: false,
              postal_code: this.selectedpostalcode,
              is_biling_address: true,
              is_shipping_address: false
            }
          };
        } else {
          for (let index = 0; index < this.addressArray.length; index++) {
            if (this.addressArray[index].is_shipping_address === true) {
              _id = this.addressArray[index]._id;
            }
          }

          obj = {
            _id: _id,
            address: {
              address_line_1: this.selectedAddressLine1,
              address_line_2: this.selectedAddressLine2,
              country: "5d8b9e5dfb195816aaea61b3",
              province: this.selectedprovince,
              region: this.selectedregion,
              city: this.selectedcity,
              postal_code: this.selectedpostalcode,
              primary: false,
              is_biling_address: false,
              is_shipping_address: true
            }
          };
        }

        let response = await this.putNewCheckoutAddress(obj);

        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Address Updated",
          showConfirmButton: false,
          timer: 1500
        });

        $("#addressModalCenter").modal("hide");
        this.getPersanalInfo();
      } catch (error) {
        console.log(error);
      }
    },
    checkAvailableStock: async function() {
      try {
        let obj = {
          ids: []
        };

        for (let index = 0; index < this.cart_products.length; index++) {
          obj.ids.push(this.cart_products[index].primary_product._id);
        }

        let response = await this.postCheckAvailableStock(obj);

        for (let index = 0; index < this.cart_products.length; index++) {
          let self = this;
          var stockarray = response.filter(function(item) {
            return item._id == self.cart_products[index].primary_product._id;
          });

          if (stockarray.length > 0) {
            this.cart_products[index].primary_product.quantity =
              stockarray[0].quantity;

            if (this.cart_products[index].primary_product.quantity <= 0) {
              this.cart_products.splice(index, 1);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    pushToItem(item_id) {
      this.$router.push({ path: "product", query: { pid: item_id } });
    },
    fetchAttributes(available_attributes, selected_attributes) {
      let attritextarr = [];
      for (
        let index = 0;
        index < selected_attributes.attributes.length;
        index++
      ) {
        let attri_id = selected_attributes.attributes[index].attribute_id;
        let attri_value =
          selected_attributes.attributes[index].attribute_value_id;
        var atti_text = available_attributes.reduce(function(
          accumulator,
          item
        ) {
          let text = "";
          if (item._id === attri_id) {
            text += item.attribute_name + ": ";

            var attval = item.attribute_values.reduce(function(
              accumulator2,
              itemval
            ) {
              if (itemval._id === attri_value) {
                console.log(item.attribute_name + ": " + itemval.value);
                attritextarr.push(item.attribute_name + ": " + itemval.value);
                return;
              }
            },
            0);
            return attval;
          }
        },
        0);
      }
      return attritextarr;
    }
  }
};
</script>
<style scoped>
.table {
  border: 0px solid #ddd;
}
</style>